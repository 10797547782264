.menuWrapper {
    --menu-row-height: 75px;
    --menu-row-hover-color: var(--main-bg-color);
    display: table-cell;
    min-height: 100vh;
    width: var(--logo-width);
    background-color: var(--top-bar-bg-color);
}

.menuWrapper .menu {
    min-height: calc(100vh - var(--top-bar-height));
}

.menuWrapper .menu .menuRow {
    height: var(--menu-row-height);
    display: table;
    width: var(--logo-width);
}

.menuWrapper .menu .menuRow .menuItem {
    height: var(--menu-row-height);
    text-align: center;
    color: white;
    display: table-cell;
    font-size: 20px;
    font-weight: bold;
    vertical-align: middle;
    background-color: --var(--main-bg-color);
    cursor: pointer;
}

.menuWrapper .menu .menuRow .menuItem:hover {
    background-color: var(--menu-row-hover-color);
    color: black;
}

.menuBottom {
    height: calc(90vh - var(--menu-row-height) * 3 - var(--top-bar-height));
    color: white;
    width: var(--logo-width);
    display: table-cell;
    text-align: center;
    vertical-align: bottom;
}
