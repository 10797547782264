.App {
  /* text-align: center; */
    --window-width: 100%;
    --logo-width: 220px;
    --top-bar-height: 150px;
    --top-bar-bg-color: #2c2e43 /* #182e49 */ /* #2d3643 */;
    height: 100%;
    display: table;
    width: 100%;
}

.App .menuAndContent {
    display: table-row;
}

.App .menuAndContent .authInfoCheck {
    margin-left: 30px;
}

.App .content {
    display: table-cell;
    padding: 20px;
    width: 100%;
}

.hideAndAddArrows {
    height: .8em;
    margin-right: 0.5em;
}

a {
    color: inherit;
}
