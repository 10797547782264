html {
    height: 100%;
}

body {
    --main-bg-color: white;
    margin: 0;
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
                 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                 sans-serif;
    background-color: var(--main-bg-color);
    color: black;
    padding: 0px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    --cluster-margin-left: 10px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
                 monospace;
}
